import React, { useContext } from 'react'
import { Field, Form, Formik } from 'formik';
import ValidationError from '../../Errors/ValidationError';
import { VehicleContext } from '../../Context/VehicleContext';

const CommonForm = () => {
    const {submitContactForm} = useContext(VehicleContext);
    
    return (
        <>
            <Formik
                // validationSchema={validationSchema}
                initialValues={{
                    full_name: '',
                    email: '',
                    phone: '',
                    preferable_date: '',
                    preferable_time: '',
                    requested_services: '',
                }}
                onSubmit={async (values, {resetForm}) => {
                    console.log(values);
                    const data = await submitContactForm(values);
                    if(data.success) {
                        alert("Thank you for your submission");
                        resetForm();
                    } else
                        alert("There was a problem with your submission");
                }}
            >
                {({ values, setFieldValue, field, form }) => (
                    <Form className="service-from" autoComplete="off" name="contact-form">
                        <div className='row mx-50 mt-4'>
                            <div className='col-md-6 px-50 wow fadeInLeft' data-wow-delay="0.4s">
                                <div className='form-group'>
                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                        <div className='cs-label mb-0'>Full Name</div>
                                        <div className='required-text d-md-none'>* Required</div>
                                    </div>
                                    <Field
                                        type="text"
                                        name="full_name"
                                        className="form-control"
                                        required
                                    />
                                    <ValidationError name="full_name" />
                                </div>
                                <div className='form-group'>
                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                        <div className='cs-label mb-0'>Email</div>
                                        <div className='required-text d-md-none'>* Required</div>
                                    </div>
                                    <Field
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        required
                                    />
                                    <ValidationError name="email" />
                                </div>
                                <div className='form-group'>
                                    <div className='cs-label mb-2'>Phone</div>
                                    <Field
                                        type="text"
                                        name="phone"
                                        className="form-control"
                                        required
                                    />
                                    <ValidationError name="phone" />
                                </div>
                            </div>
                            <div className='col-md-6 px-50 wow fadeInRight' data-wow-delay="0.6s">
                                <div className='form-group'>
                                    <div className='cs-label'>Comments</div>
                                    <Field
                                        as="textarea"
                                        name="comment"
                                        className="form-control h-247"
                                        component="textarea"
                                    />
                                    <ValidationError name="comment" />
                                </div>
                                <div className='mt-4 text-end'>
                                    <button type='submit' className='green-btn lg-btn w-300'>Send</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default CommonForm