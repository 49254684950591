import React, { useState, useEffect } from 'react'
import { getImages } from '../../const';


const SelectFilterModal = ({ close,filterData,updateFilter,activeFilter }) => {

    const [boxes, setBoxes] = useState(filterData);

    const [selectedCount, setSelectedCount] = useState(0);

    const handleCheckboxChange = (index) => {
        const newBoxes = [...boxes];
        newBoxes[index].isSelected = !newBoxes[index].isSelected;
        setBoxes(newBoxes);

        updateFilter(newBoxes);

        setSelectedCount(selectedCount + (newBoxes[index].isSelected ? 1 : -1) * newBoxes[index]['available']);
    };
    const clearAllSelections = () => {
        const newBoxes = [];

        boxes.forEach((v) => {
            newBoxes.push({...v,isSelected:false})
        });

        setBoxes(newBoxes);

        updateFilter(newBoxes);

        setSelectedCount(0);
    };

    useEffect(() => {
        var selected = 0;

        for(var key in filterData) {
            selected += filterData[key].isSelected ? filterData[key].available : 0;
        }

        setSelectedCount(selected);
        setBoxes(filterData);
    }, [filterData]);

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title filter-modal-title">
                        Select {activeFilter}
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <img src={getImages('white-close.svg')}/>
                    </button>
                </div>
                <div className="modal-body px-4 py-4">
                    <div className='row make-box-list'>
                        {boxes.map((v, i) => (
                            v.isVisible ? <div className='col-md-4' key={i}>
                                <div className={`make-box d-flex align-items-center justify-content-between ${v.isSelected ? 'selected' : ''}`}>
                                    <div>
                                        <div className='sm-title fw-700 mb-2'>{v.name}</div>
                                        <div className='xs-title fw-300 font-13 text-white'>{v.available} available</div>
                                    </div>
                                    <div>
                                        <label className="cs-checkbox">
                                            <input type="checkbox"
                                            id={`makeBox${i}`}
                                            checked={v.isSelected}
                                            onChange={() => handleCheckboxChange(i)}/>
                                            <label htmlFor={`makeBox${i}`}></label>
                                        </label>
                                    </div>
                                </div>
                            </div> : <React.Fragment key={i}></React.Fragment>
                        ))}
                    </div>
                </div>
                <div className="modal-footer d-flex align-items-center justify-content-between">
                    <div>
                        <button className='transparent-btn text-green text-decoration-underline' type='button' onClick={clearAllSelections}>Clear Selection</button>
                    </div>
                    <div>
                        <button className='green-btn' type='button' onClick={close}>{"View " + (selectedCount > 0 ? selectedCount + " " : "") + "Match" + ((selectedCount > 1 || selectedCount == 0 ? "es" : ""))}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectFilterModal