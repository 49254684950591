import React from 'react'
import { Link } from 'react-router-dom'

const HomeSales = () => {
    return (
        <>
            <div id="salesDiv" className="panel-inner add-overlay-bg sales-main-block">
                <div className='pi-pos'>
                    <div className='container'>
                        <div className='xxl-title'>Sales</div>
                        <div className='xl-title my-4'>We only stock the world's best cars. <br />Absolutely no stories.</div>
                        <div className='mt-5 text-center'>
                            <Link to="/srp?dealer_inventory" className='green-btn xl-btn d-inline-block'>View Collection</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeSales