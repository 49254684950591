import React, {useEffect, useContext, useState} from 'react'
import { Routes, Route } from "react-router-dom";
import Home2 from './Pages/Frontend/Home2';
import Pairings from './Pages/Frontend/pairings';
import Srp from './Pages/Frontend/srp';
import Service from './Pages/Frontend/Service';
import Vdp from './Pages/Frontend/vdp';
import About from './Pages/Frontend/About';
import Auction from './Pages/Frontend/Auction';
import Contact from './Pages/Frontend/Contact';
import FerrariService from './Pages/Frontend/FerrariService';
import OurCommunity from './Pages/Frontend/OurCommunity';
import AuctionsList from './Pages/Frontend/AuctionsList';
import { UserContext } from './Componment/Context/UserContext';
import Home from './Pages/Frontend/Home';
import ExoticCarAppraisal from './Pages/Frontend/ExoticCarAppraisal';

const AppRoutes = () => {

  const { user, loggedInCheck } = useContext(UserContext);

    const [checkedLogin, setCheckedLogin] = useState(null);

    useEffect(() => {
		if (!checkedLogin) {
			checkLogin();
		}
	}, []);

	const checkLogin = async () => {
		console.log('checking login');
		await loggedInCheck();
		setCheckedLogin(true);
		console.log(user);
	};

  return (
    <>
        <Routes>
            <Route exact path="/" Component={Home} />
            <Route exact path="/home-2" Component={Home2} />
            <Route exact path="/vdp" Component={Vdp} />
            <Route exact path="/srp" Component={Srp} />
            <Route exact path="/pairings" Component={Pairings} />
            <Route exact path="/service" Component={Service} />
            <Route exact path="/about" Component={About} />
            <Route exact path="/auctions" Component={AuctionsList} />
            <Route exact path="/auction-details" Component={Auction} />
            <Route exact path="/contact" Component={Contact} />
            <Route exact path="/ferrari-service" Component={FerrariService} />
            <Route exact path="/our-community" Component={OurCommunity} />
            <Route exact path="/exotic-car-appraisal" Component={ExoticCarAppraisal} />
        </Routes>
    </>
  )
}

export default AppRoutes