import React, { useContext, useState } from 'react'
import { getImages } from '../const'
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import ValidationError from '../../Componment/Errors/ValidationError';
import { VehicleContext } from '../Context/VehicleContext';
import { ChatContext } from '../Context/ChatContext';

const validationSchema = (isStep2Visible) => Yup.object({
    full_name: Yup.string()
        .min(2, 'Full Name must be at least 2 characters long')
        .required('Full Name is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    phone: Yup.string()
        .matches(/^[0-9]+$/, 'Phone number must be digits only')
        .min(10, 'Phone number must be at least 10 digits')
        .required('Phone number is required'),
    mileage: isStep2Visible
        ? Yup.string().required('Mileage is required')
        : Yup.string().notRequired(),
});
const AppraiseCarModal = ({ close, formikRef }) => {
    const {uploadDocument} = useContext(ChatContext);
    const {submitContactForm} = useContext(VehicleContext);

    const [isStep2Visible, setIsStep2Visible] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (event, setFieldValue) => {
        const files = Array.from(event.target.files);
        const newImages = files.map(file => URL.createObjectURL(file));
        setSelectedImages(prevImages => [...prevImages, ...newImages]);
        //setFieldValue('photos', event.target.files);

        uploadFiles(event.target.files);
    };

    const handleDrop = (event, setFieldValue) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        const newImages = files.map(file => URL.createObjectURL(file));
        setSelectedImages(prevImages => [...prevImages, ...newImages]);
        //setFieldValue('photos', event.dataTransfer.files);

        uploadFiles(event.dataTransfer.files);
    };

    const handleRemoveImage = (image) => {
        setSelectedImages(prevImages => prevImages.filter(img => img !== image));
    };
    const closeStep2 = () => {
        setIsStep2Visible(false);
    }
    const [step2Err, setStep2Err] = useState(false);
    const finishHandle = () => {
        setStep2Err(true)
    }

    const uploadFiles = (files) => {
        Array.from(files).map((v,i) => {
            uploadFile(v);
        });
    }

    const [formSubmitting,setFormSubmitting] = useState(false);

    const uploadFile = (file) => {
        console.log(file);

        if (file) {

            console.log(file);

            /*var fileSize = 0;
            var messageType = "";
            // if(['application/pdf'].includes(file.type)>-1){
            if((file.type).includes('application') || (file.type).includes('text')){
                fileSize = ((file.size / (1024*1024)));
                var ext = (fileSize<=0)?"KB":"MB";
                fileSize = (fileSize.toFixed(2)).padStart(2, ' ')+ext;
                messageType = "pdf"
            }

            if(['image/jpeg','image/jpg', 'image/png'].indexOf(file.type) > -1){
                messageType = "png"
            }*/

            const formData = new FormData();
            formData.append('file', file);
            formData.append('folder', 'userappraisals/');
            formData.append('fileName',file.name);
            //formData.append('base_url',true);

            const upload = uploadDocument(formData);
            upload.then((data) => {
                if (data.url && data.data && data.data.key) {
                    //sendChat("",activeChatUser,data.data.key,data.url);

                    formikRef.current.setFieldValue("photos",[...formikRef.current.values.photos, data.url]);

                    console.log(formikRef.current.values);
                    
                } else if(data.error) alert(data.error);
            });
        }
            
    }
    return (
        <div className="modal-content appraise-car-modal" style={{ background: `#000 url(${getImages('appraise-car-bg.png')}) no-repeat left top` }}>
            <Formik
                enableReinitialize
                initialValues={{
                    vehicle: '',
                    full_name: '',
                    email: '',
                    phone: '',
                    vin: '',
                    mileage: '',
                    comment: '',
                    year: '',
                    make: '',
                    model: '',
                    trim: '',
                    photos: []
                }}
                innerRef={formikRef}
                validationSchema={validationSchema(isStep2Visible)}                
                onSubmit={async (values, {resetForm}) => {
                    console.log(values);
                    setFormSubmitting(true);
                    const data = await submitContactForm(values);
                    setFormSubmitting(false);
                    if(data.success) {
                        alert("Thank you for your submission");
                        resetForm();
                        close();
                    } else
                        alert("There was a problem with your submission");
                }}
            >
                {({ validateForm, values, setFieldValue, field, form, setTouched, resetForm  }) => (
                    <Form autoComplete="off" name="contact-form">
                        <Field type="hidden" name="vehicle" value={values.vehicle} />
                        <Field type="hidden" name="year" value={values.year} />
                        <Field type="hidden" name="make" value={values.make} />
                        <Field type="hidden" name="model" value={values.model} />
                        <Field type="hidden" name="trim" value={values.trim} />
                        <FieldArray type="hidden" name="photos" value={values.photos} />
                        <div className="modal-header">
                            <h1 className="modal-title filter-modal-title text-center">
                                Let’s appraise your Car
                            </h1>
                            <button className="sm-box-close" type="button" 
                                onClick={() => {
                                    close();
                                    closeStep2();
                                    resetForm();
                                }}
                            >
                                <img src={getImages('white-close.svg')} />
                            </button>
                        </div>
                        <div className='service-from'>
                            <div className="modal-body p-0">
                            {!isStep2Visible ? 
                                <div className='appraise-car-box mx-auto step1'>
                                    <div className='md-title fw-400 font-1-5em'>Almost there, just need a few details</div>
                                    <div className='form-group'>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='cs-label mb-0'>Full Name</div>
                                            <div className='required-text'>* Required</div>
                                        </div>
                                        <Field
                                            type="text"
                                            name="full_name"
                                            className="form-control"
                                            required
                                        />
                                        <ValidationError name="full_name" />
                                    </div>
                                    <div className='form-group'>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='cs-label mb-0'>Email</div>
                                            <div className='required-text'>* Required</div>
                                        </div>
                                        <Field
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            required
                                        />
                                        <ValidationError name="email" />
                                    </div>
                                    <div className='form-group'>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='cs-label mb-0'>Phone</div>
                                            <div className='required-text'>* Required</div>
                                        </div>
                                        <Field
                                            type="text"
                                            name="phone"
                                            className="form-control"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();  // Prevent non-numeric characters
                                                }
                                            }}
                                            required
                                        />
                                        <ValidationError name="phone" />
                                    </div>
                                </div> :
                                <div className='appraise-car-box mx-auto step2'>
                                    <div className='md-title fw-400 font-1-5em'>Vehicle Information</div>
                                    <div className='row'>
                                        <div className='col-md-6 form-group'>
                                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                                <div className='cs-label mb-0'>VIN</div>
                                                <div className='required-text'>Optional</div>
                                            </div>
                                            <Field
                                                type="text"
                                                name="vin"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                                <div className='cs-label mb-0'>Mileage</div>
                                                <div className='required-text'>* Required</div>
                                            </div>
                                            <Field
                                                type="text"
                                                name="mileage"
                                                className="form-control"
                                            />
                                            {step2Err && <ValidationError name="mileage" />}
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='cs-label mb-0'>Comments</div>
                                            <div className='required-text'>Optional</div>
                                        </div>
                                        <Field
                                            as="textarea"
                                            name="comment"
                                            className="form-control h-120"
                                        />
                                    </div>
                                    <div className='text-center browse-photo-box'
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(event) => handleDrop(event, setFieldValue)} 
                                    >
                                        {selectedImages.length > 0 ? (
                                            <div className='d-inline-flex justify-content-center'>
                                                {selectedImages.slice(0, 4).map((image, index) => (
                                                    <div key={index} className='uploaded-box mx-1 position-relative'>
                                                        <button type='button' className='upload-close' onClick={() => handleRemoveImage(image)}>
                                                            <img src={getImages('cancel.png')}/>
                                                        </button>
                                                        <img src={image} alt={`Uploaded ${index}`} className="uploaded-photo-preview" />
                                                        {/* {selectedImages.length > 4 && index === 3 ? (
                                                            <div className='total-conunt'>
                                                                <div className='tc-label'>+{selectedImages.length - 4}</div>
                                                            </div>
                                                        ) : null} */}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <img src={getImages('icon-upload-photo.svg')} alt="Upload icon" />
                                        )}
                                        {selectedImages.length > 0 ? 
                                            <div className='bpb-title'>{selectedImages.length} PHOTOS UPLOADED</div>: 
                                            <div className='bpb-title'>Drag Photos</div>
                                        }
                                        <label className="green-btn" htmlFor="photo-upload">
                                            Browse Photos
                                        </label>
                                        <input
                                            type="file"
                                            id="photo-upload"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(event) => handleImageChange(event, setFieldValue)}
                                        />
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="modal-footer">
                                <div className='d-flex align-items-center justify-content-between w-100'>
                                    <div className='mnw-195 d-none d-md-block'>
                                        {!isStep2Visible ? `(&nbsp;)` : 
                                        <button className='eca-back-btn text-green'
                                            onClick={() => {
                                                setIsStep2Visible(false);
                                            }}
                                        >Back</button>
                                        }
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='md-title text-center'>
                                            {!isStep2Visible ? "1" : "2"} of 2
                                        </div>
                                        <div className='ps-2 d-md-none'>
                                            {!isStep2Visible ? `(&nbsp;)` : 
                                            <button className='eca-back-btn text-green'
                                                onClick={() => {
                                                    setIsStep2Visible(false);
                                                }}
                                            >Back</button>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {isStep2Visible ? 
                                            (formSubmitting ? <></> : <button type='submit' className='green-btn mnw-195' onClick={finishHandle}>Finish</button>)
                                            : 
                                            <button type='button' className='green-btn mnw-195'
                                                onClick={async () => {
                                                    if (!isStep2Visible) {
                                                        const errors = await validateForm();
                                                        if (Object.keys(errors).length === 0) {
                                                            setIsStep2Visible(true);  // Proceed to Step 2
                                                        } else {
                                                            // Mark fields from step 1 as touched to show error messages
                                                            setTouched({
                                                                full_name: true,
                                                                email: true,
                                                                phone: true,
                                                            });
                                                        }
                                                    }
                                                }}
                                            >Next</button>
                                    }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AppraiseCarModal