import React, { useContext, useState, useEffect, useRef } from 'react'
import { getImages } from '../../const'
import { Link, NavLink, useLocation } from 'react-router-dom'
import ModalLayout from './ModalLayout';
import LoginModal from '../Auction/LoginModal';
import RegisterModal from '../Auction/RegisterModal';
import ForgotPassword from '../Auction/ForgotPassword';
import { UserContext } from '../../Context/UserContext';
import GrandOpeningModal from './GrandOpeningModal';

const Header = () => {
    const { user, logout } = useContext(UserContext);

    const [isMob, setisMob] = useState(false);
    const onChangeBodyClass = (value) => {
        setisMob(value);
        value
            ? document.body.classList.add('overflow-active')
            : document.body.removeAttribute('class');
    };
    // Login Modal
    const [loginModal, setLoginModal] = useState(false);
    const handleLoginModal = () => {
        setLoginModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeLoginModal = () => {
        setLoginModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Register Modal
    const [registerModal, setRegisterModal] = useState(false);
    const handleRegisterModal = () => {
        setRegisterModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeRegisterModal = () => {
        setRegisterModal(false);
        document.body.classList.remove('overflow-body');
    }
    // ForgotPwd Modal
    const [forgotPwdModal, setForgotPwdModal] = useState(false);
    const handleForgotPwdModal = () => {
        setForgotPwdModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeForgotPwdModal = () => {
        setForgotPwdModal(false);
        document.body.classList.remove('overflow-body');
    }
    const [openSubMneu,  setOpenSubMenu] = useState(false)
    const handleSubMenu = (menu) => {
        setOpenSubMenu((prevMenu) => (prevMenu === menu ? null : menu));
    }
    const closeMenu = () => {
        setisMob(false); 
        setOpenSubMenu(false);
        document.body.classList.remove('overflow-active');
    }

    // Add class on Home page
    const location = useLocation();

    useEffect(() => {
        const headerElement = document.querySelector('header');
        if (location.pathname === '/') {
            headerElement.classList.add('home-header');
        } else {
            headerElement.classList.remove('home-header');
        }
    }, [location]);

    const sectionRef = useRef(null);
    useEffect(() => {
      if (location.search.includes('dealer_inventory') || location.pathname.includes('service')) {
        if (sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
    return (
        <>
            <header className="header-wrap" ref={sectionRef}>
                <div className="container mxw-1366">
                    <div className="header-block d-flex align-items-center justify-content-between">
                        <div className="header-left">
                            <Link to="/" className="main-logo d-block">
                                <img src={getImages('main-logo.svg')} alt='logo' />
                            </Link>
                        </div>
                        <div className="header-right d-flex align-items-center justify-content-between">
                            <div className={`d-flex w-100 align-items-center justify-content-between menu-block ${isMob ? 'active' : ''}`}>
                                <div className="main-menu">
                                    <ul>
                                        <li>
                                            <NavLink to="/" onClick={closeMenu}>Home</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/srp?dealer_inventory" onClick={closeMenu}>Inventory</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink to="/auctions" onClick={closeMenu}>Live Auctions</NavLink>
                                        </li> */}
                                        <li>
                                            <NavLink to="/exotic-car-appraisal" onClick={closeMenu}>Sell Us Your Car</NavLink>
                                        </li>
                                        <li className={`sub-menu ${openSubMneu === 'service' ? 'open' : ''}`}>
                                            <NavLink to="/service" onClick={closeMenu} className="d-none d-lg-block">Service <span className='menu-arrow-icon'></span></NavLink>
                                            <a href="#" onClick={() => handleSubMenu('service')} className='d-lg-none'>Service <span className='menu-arrow-icon'></span></a>
                                            <ul className='sub-mega-menu service-m-menu'>
                                                <li className='w-100'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='smm-col'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='icon-img'>
                                                                    <img src={getImages('icon-service1.svg')}/>
                                                                </div>
                                                                <div className='d-none d-lg-block md-title ps-3 fw-400'>Service</div>
                                                                <NavLink to="/service" onClick={closeMenu} className="d-lg-none md-title fw-400 text-white">Service</NavLink>
                                                            </div>
                                                            <ul className='inside-menu'>
                                                                <li>
                                                                    <Link to='/service' onClick={closeMenu}>Highline Service</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/ferrari-service" onClick={closeMenu}>Ferrari Vintage Pricing</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* <div className='smm-col'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='icon-img'>
                                                                    <img src={getImages('perk-icon3.svg')}/>
                                                                </div>
                                                                <div className='md-title ps-3 fw-400'>Parts</div>
                                                            </div>
                                                            <ul className='inside-menu'>
                                                                <li>
                                                                    <a href='#' onClick={closeMenu}>Order Parts</a>
                                                                </li>
                                                                <li>
                                                                    <a href='#' onClick={closeMenu}>Wholesale Parts</a>
                                                                </li>
                                                                <li>
                                                                    <a href='#' onClick={closeMenu}>Custom Accesories</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='smm-col'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='md-title ps-0 fw-400 mnh-33'>Section Name</div>
                                                            </div>
                                                            <ul className='inside-menu'>
                                                                <li>
                                                                    <a href='#' onClick={closeMenu}>Sub Item</a>
                                                                </li>
                                                                <li>
                                                                    <a href='#' onClick={closeMenu}>Sub Item</a>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className={`sub-menu ${openSubMneu === 'about' ? 'open' : ''}`}>
                                            <NavLink to="/about" onClick={closeMenu} className="d-none d-lg-block">About <span className='menu-arrow-icon'></span></NavLink>
                                            <a href="#" onClick={() => handleSubMenu('about')} className='d-lg-none'>About <span className='menu-arrow-icon'></span></a>
                                            <ul className='sub-mega-menu about-m-menu'>
                                                <li className='w-100'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='smm-col'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='icon-img'>
                                                                    <img src={getImages('Community-icon.svg')}/>
                                                                </div>
                                                                <div className='d-none d-lg-block md-title ps-3 fw-400'>About</div>
                                                                <NavLink to="/about" onClick={closeMenu} className="d-lg-none md-title fw-400 text-white">About</NavLink>
                                                            </div>
                                                            <ul className='inside-menu'>
                                                                <li>
                                                                    <Link to='/about' onClick={closeMenu}>Who We Are</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/our-community" onClick={closeMenu}>Our Community</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <NavLink to="/contact" onClick={closeMenu}>Contact</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="login-block">
                                    {user ? <button className="green-btn" type="button" onClick={() => logout()}>Logout</button> : <button className="green-btn" type="button" onClick={handleLoginModal}>Login</button>}
                                    
                                </div>
                            </div>
                            <div className={`overflow-bg ${isMob ? 'active' : ''
                                }`} onClick={() => onChangeBodyClass(!isMob)}></div>
                            <div
                                className={`mobile-inner-header ${isMob ? 'active' : ''}`}
                                onClick={() => onChangeBodyClass(!isMob)}
                            >
                                <div
                                    className={`mobile-inner-header-icon mobile-inner-header-icon-out ${isMob ? 'active' : ''
                                        }`}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {loginModal &&
                <ModalLayout open={loginModal} close={closeLoginModal} modalWidth={980}>
                    <LoginModal
                        close={closeLoginModal}
                        handleRegisterModal={handleRegisterModal}
                        handleForgotPwdModal={handleForgotPwdModal}
                    />
                </ModalLayout>
            }
            {registerModal &&
                <ModalLayout open={registerModal} close={closeRegisterModal} modalWidth={680}>
                    <RegisterModal close={closeRegisterModal} />
                </ModalLayout>
            }
            {forgotPwdModal &&
                <ModalLayout open={forgotPwdModal} close={closeForgotPwdModal} modalWidth={680}>
                    <ForgotPassword close={closeForgotPwdModal} />
                </ModalLayout>
            }
        </>
    )
}

export default Header