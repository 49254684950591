import React, { useEffect, useRef, useState, useContext } from 'react'
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { getImages, LazyImage } from '../../Componment/const';
import { Link } from 'react-router-dom';
import FeaturedVehicles  from '../../Componment/Frontend/Home/FeaturedVehicles';
import GrandOpeningComingSoon  from '../../Componment/Frontend/Home/GrandOpeningComingSoon';
import JoinMallingList  from '../../Componment/Frontend/Home/JoinMallingList';
import HomeSales  from '../../Componment/Frontend/Home/HomeSales';
import HomeSalesInside  from '../../Componment/Frontend/Home/HomeSalesInside';
import HomeServiceInside  from '../../Componment/Frontend/Home/HomeServiceInside';
import HomeService  from '../../Componment/Frontend/Home/HomeService';

const Home = () => {

    const [openMobAccordion, setOpenMobAccordion] = useState(1);
    useEffect(() => {
        const interval = setInterval(() => {
            setOpenMobAccordion(prev => (prev % 3) + 1); // Cycle through 1, 2, 3
        }, 5000);

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    const handleMobAccordion = (item) => {
        setOpenMobAccordion(item)
    }
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.autoplay = true;
            videoRef.current.muted = true;
            videoRef.current.loop = false;
            videoRef.current.controls = false;
        }
    }, []);

    const [isMobileView, setIsMobileView] = useState(false);
    const updateView = () => {
        setIsMobileView(window.innerWidth <= 991);
    };
    
    // Grand Opening Modal

    const [openGrandOpening, setOpenGrandOpening] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setOpenGrandOpening(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);
    const closeGrandOpening = () => {
        setOpenGrandOpening(false)
    }
    useEffect(() => {
        if (openGrandOpening) {
            setFadeIn(true);
        }
    }, [openGrandOpening]);

    const handleJoinMailingList = () => {
        setOpenGrandOpening(false)
        setTimeout(() => {
            const id = 'join-mailing-form';
            document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
    }
    // FadeIn video
    useEffect(() => {
        const videoElement = videoRef.current;
        const handleVideoEnd = () => {
            videoElement.classList.add('video-fade-in');
            videoElement.play();
            setTimeout(() => {
                // videoElement.currentTime = 0;
                videoElement.classList.remove('video-fade-in');
            }, 3000);
        };
        if (videoElement) {
            videoElement.addEventListener('ended', handleVideoEnd);
        }
        return () => {
            if (videoElement) {
                videoElement.addEventListener('ended', handleVideoEnd);
            }
        };
    }, []);


    const salesSectionRef = useRef(null);

    const scrollToSection = (sectionRef) => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    // Scrollmagic mobile conditions 
    const [isMobile, setIsMobile] = useState(false);
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        handleResize();
        updateView();
        window.addEventListener('resize', handleResize, updateView);
        return () => {
            window.removeEventListener('resize', handleResize, updateView);
        };
    }, []);

    const handleRemoveUrlId = (e) => {
        e.preventDefault();
        const targetId = e.currentTarget.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            window.history.replaceState(null, null, ' ');
        }
    };
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        // Wait for the page to fully load
        const handlePageLoad = () => {
            setIsPageLoaded(true);
        };

        window.addEventListener('load', handlePageLoad);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('load', handlePageLoad);
        };
    }, []);

    const isMobile1 = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
      };
      useEffect(() => {
        if (isMobile1()) {
          const preloadVideo = document.createElement("link");
          preloadVideo.rel = "preload";
          preloadVideo.as = "video";
          preloadVideo.href = `${getImages('banner_video.mp4')}`;
          document.head.appendChild(preloadVideo);
        }
      }, []);
    return (
        <>
            <div className='parallax-wrap'>
                <section className='panel-banner-wrap'>
                    <div className="banner-wrap home-banner pt-0 pb-5">
                        <div className='video-relative'>
                            {/* {isPageLoaded ? ( */}
                            {!isMobile &&   
                                <video className='video-background' width="100%" ref={videoRef} muted loop playsInline autoPlay preload="auto">
                                    <source src={`${getImages('banner_video.mp4')}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> 
                            }
                            {isMobile &&   
                                <video className='video-background m-video' width="100%" ref={videoRef} muted loop playsInline autoPlay preload="auto">
                                    <source src={`${getImages('mobile_banner_video.mp4')}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            }
                            {/* ): 
                                <img src={getImages('banner_video_img.webp')} alt='banner video'/>
                            } */}
                        </div>
                        <div className='home-overlay-pos'>
                            <div className='home-banner-pos'>
                                <div className='container'>
                                    <div className='d-flex align-items-center banner-flex'>
                                        <div className='w-55 position-relative h-225'>
                                            <div className='d-flex align-items-center'>
                                                <div className='m-title m-title1 wow fadeInLeft' data-wow-delay="0.4s">
                                                    <img src={getImages('m-title1.webp')} alt="" />
                                                </div>
                                                <div className='m-title m-title2 wow fadeInLeft' data-wow-delay="0.8s">
                                                    <img src={getImages('m-title2.webp')} alt="" />
                                                </div>
                                                <div className='m-title m-title3 wow fadeInLeft' data-wow-delay="1.2s">
                                                    <img src={getImages('m-title3.webp')} alt="" />
                                                </div>
                                            </div>
                                            <div className='m-title m-title4 wow brookLinAnimation' data-wow-delay="2.6s">
                                                <LazyImage src={getImages('m-title4.webp')} alt="" />
                                            </div>
                                        </div>
                                        <div className={`w-45 ${isMobileView ? 'wow zoomIn' : ''}`} {...(!isMobileView && { 'data-wow-delay': '1.2s' })}>
                                            <div className={`banner-box wow fadeInRight ${openMobAccordion === 1 ? "active" : ""}`} data-wow-delay="0.4s">
                                                <div className='d-flex align-items-center' onClick={() => handleMobAccordion(1)}>
                                                    <div className='bb-icon'>
                                                        <img src={getImages('icon-sales.svg')} alt="" />
                                                    </div>
                                                    <div className='bb-content ps-4'>
                                                        <div className='bb-title mb-2'>Sales</div>
                                                        <p>Our cars aren’t the cheapest, but they are the best. No stories. <a href="#salesDiv" onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></p>
                                                    </div>
                                                </div>
                                                <div className={`mob-bb-content ${openMobAccordion === 1 ? "" : "d-none"}`}>Our cars aren’t the cheapest, but they are the best. No stories. <a href="#salesDiv" onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></div>
                                            </div>
                                            <div className={`banner-box wow fadeInRight ${openMobAccordion === 2 ? "active" : ""}`} data-wow-delay="0.6s">
                                                <div className='d-flex align-items-center' onClick={() => handleMobAccordion(2)}>
                                                    <div className='bb-icon'>
                                                        <img src={getImages('icon-service.svg')} alt="" />
                                                    </div>
                                                    <div className='bb-content ps-4'>
                                                        <div className='bb-title mb-2'>Service</div>
                                                        <p>Ferrari Master Techs. Expert hands turn our wrenches. <a href="#serviceDiv" onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></p>
                                                    </div>
                                                </div>
                                                <div className={`mob-bb-content ${openMobAccordion === 2 ? "" : "d-none"}`}>Ferrari Master Techs. Expert hands turn our wrenches. <a href="#serviceDiv" onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></div>
                                            </div>
                                            <div className={`banner-box wow fadeInRight ${openMobAccordion === 3 ? "active" : ""}`} data-wow-delay="0.8s">
                                                <div className='d-flex align-items-center' onClick={() => handleMobAccordion(3)}>
                                                    <div className='bb-icon'>
                                                        <img src={getImages('Community-icon.svg')} alt="" />
                                                    </div>
                                                    <div className='bb-content ps-4'>
                                                        <div className='bb-title mb-2'>Who We Are</div>
                                                        <p>We believe in a world that existed before the internet ruined the fun. We negotiate, we laugh and in our world a handshake is legally binding. <Link to="/about" className='text-green h-read-more'>Read more</Link></p>
                                                    </div>
                                                </div>
                                                <div className={`mob-bb-content ${openMobAccordion === 3 ? "" : "d-none"}`}>We believe in a world that existed before the internet ruined the fun. We negotiate, we laugh and in our world a handshake is legally binding. <Link to="/about" className='text-green h-read-more'>Read more</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => scrollToSection(salesSectionRef)}  className='scroll-down-btn' style={{ cursor: 'pointer' }}>
                        <LazyImage src={getImages('arrow-down-circle.svg')} alt="" />
                        <div>Scroll</div>
                    </div>
                    <div ref={salesSectionRef} style={{height: '0px', position: 'absolute',
                        bottom: '0px', zIndex: '1'
                    }}></div>
                </section>
                {isMobile && 
                    <>
                        <HomeSales/>
                        <HomeSalesInside/>
                    </>
                }
                <div className={`${isMobile ? 'd-none' : ''}`}>
                    <Controller>
                        <Scene
                            triggerHook="onLeave"
                            duration="150%"
                            pin
                        >
                            <Timeline
                                wrapper={<div id="pinContainer" />}
                            >
                                <Tween
                                    from={{ y: '0%' }}
                                    to={{ y: '0%' }}
                                >
                                <section className='panel'>
                                    <HomeSales/>
                                </section>
                                </Tween>
                                <Tween
                                    from={{ x: '100%' }}
                                    to={{ x: '0%' }}
                                >
                                    <section className='panel'>
                                        <HomeSalesInside/>
                                    </section>
                                </Tween>
                            </Timeline>
                        </Scene>
                    </Controller>
                </div>
                {isPageLoaded && <FeaturedVehicles/>}
                {isMobile &&
                    <>
                        <HomeService/>
                        <HomeServiceInside/>
                    </>
                }
                <div className={`${isMobile ? 'd-none' : ''}`}>
                    <Controller>
                        <Scene
                            triggerHook="onLeave"
                            duration="150%"
                            pin
                        >
                            <Timeline
                                wrapper={<div id="pinContainer1" />}
                            >
                                <Tween
                                    from={{ y: '0%' }}
                                    to={{ y: '0%' }}
                                >
                                    <section className='panel'>
                                        <HomeService/>
                                    </section>
                                </Tween>
                                <Tween
                                    from={{ x: '-100%' }}
                                    to={{ x: '0%' }}
                                >
                                    <section className='panel'>
                                        <HomeServiceInside/>
                                    </section>
                                </Tween>
                            </Timeline>
                        </Scene>
                    </Controller>
                </div>
            </div>
            <GrandOpeningComingSoon/>
            <JoinMallingList/>
            {/* {openGrandOpening &&
                <ModalLayout open={openGrandOpening} close={closeGrandOpening}  darkThemeCls='true' grandOpening="true" fadeIn={fadeIn}  modalWidth={800}>
                    <GrandOpeningModal close={closeGrandOpening} handleJoinMailingList={handleJoinMailingList}/>
                </ModalLayout>
            } */}
        </>
    );
};

export default Home;
