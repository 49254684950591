import React, { useEffect, useRef, useState, useContext } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { VehicleContext } from '../../Context/VehicleContext';
import { LazyImage } from '../../const';
const FeaturedVehicles = () => {

    const [showSlider, setShowSlider] = useState(false);

    const { vehicleData, numberFormatter, getVehicleData } = useContext(VehicleContext);

    const optionssl = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    arrows: false,
                    centerPadding: '60px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: '30px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: '0',
                    slidesToShow: 2
                }
            }
        ]
    };

    useEffect(() => {
        getVehicleData();
    },[])

    useEffect(() => {
        const handleResize = () => {
            setShowSlider(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);

        // Call the handler immediately so the component starts with the correct state
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [featuredList, setFeaturedList] = useState([]);

    useEffect(() => {
        if (vehicleData.length > 0) {
            setFeaturedList(vehicleData.sort((a, b) => b.price - a.price).slice(0, 8))
            setShowSlider(window.innerWidth > 768);
        }
    }, [vehicleData]);
    return (
        <>
            <section className='featured-vehicles-wrap'>
                <div className='container'>
                    <div className='main-heading mb-md-5 mb-4 wow fadeIn pt-0' data-wow-delay="0.5s">Featured Vehicles</div>
                </div>
                <div className='wow fadeIn fv-carousel-block' data-wow-delay="0.5s">
                    {showSlider ?
                        <Slider
                            className="featured-slider"
                            {...optionssl}
                        >
                            {featuredList.map((vehicle, index) =>
                                <div className="slide-item" key={index}>
                                    <div className='fv-box'>
                                        <div className='fvb-top'>
                                            <LazyImage src={vehicle.images[0]} alt="car" />
                                        </div>
                                        <div className='fvb-bottom'>
                                            <div className='sm-title mb-2'>{vehicle.vehicle_name ? vehicle.vehicle_name : '\u00A0'}</div>
                                            <div className='xs-title mb-2'>{numberFormatter(vehicle.mileage)} miles</div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='sm-title'>{numberFormatter(vehicle.price, true)}</div>
                                                <div>
                                                    <a className='green-btn sm-btn' type='button' href={"/vdp?vin=" + vehicle.vin}>More Info</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                        :
                            <Slider
                                className="featured-slider"
                                {...optionssl}
                            >
                                {featuredList.reduce((result, vehicle, index) => {
                                    // Group items in pairs
                                    if (index % 2 === 0) {
                                        result.push(featuredList.slice(index, index + 2));
                                    }
                                    return result;
                                }, []).map((vehiclePair, pairIndex) => (
                                    <div className="slide-item w-100" key={pairIndex}>
                                        {vehiclePair.map((vehicle, vehicleIndex) => (
                                            <div className='fv-box mb-4' key={vehicleIndex}>
                                                <div className='fvb-top'>
                                                    <LazyImage src={vehicle.images[0]} alt="car" />
                                                </div>
                                                <div className='fvb-bottom'>
                                                    <div className='sm-title mb-2'>{vehicle.vehicle_name ? vehicle.vehicle_name : '\u00A0'}</div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='xs-title'>{numberFormatter(vehicle.mileage)} miles</div>
                                                        <div className='sm-title'>{numberFormatter(vehicle.price, true)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </Slider>
                    }
                </div>
                <div className='text-center mt-md-5 wow fadeInUp' data-wow-delay="0.4s">
                    <a className='green-btn w-330 md-btn d-inline-block' href="/srp?dealer_inventory">View All Vehicles</a>
                </div>
            </section>
        </>
    )
}

export default FeaturedVehicles