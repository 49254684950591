import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./assets/css/all.min.css";
import './assets/css/chat.css'
import './assets/css/animate.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import { getImages } from './Componment/const';

const Layout  = React.lazy(() =>  import('./Componment/Frontend/Layout'));

function App() {
  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };
  useEffect(() => {
    if (isMobile()) {
      const preloadImage = document.createElement("link");
      preloadImage.rel = "preload";
      preloadImage.as = "image";
      preloadImage.href = `${getImages('sales-full-bg.webp')}`;
      document.head.appendChild(preloadImage);
    }
  }, []);
  return (
    <>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Layout>
          <AppRoutes />
        </Layout>
      </Suspense>
    </BrowserRouter>
    </>
  );
}

export default App;
