import React, { useEffect, useState, useRef } from 'react'
import { getImages } from '../../Componment/const'
import CountUp from 'react-countup';
import ModalLayout from '../../Componment/Frontend/Common/ModalLayout';
import AppraiseCarModal from '../../Componment/ExoticCarAppraisal/AppraiseCarModal';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { InstantSearch, ClearRefinements , Hits, useSearchBox, useHits } from 'react-instantsearch';

const searchClient = algoliasearch(
  'E8DWRSZE4Q',
  '2e1db67e90eec79f4968c0073885c388'
);

const CustomSearchBox = (props) => {
  const {
    query,
    refine,
    clear,
    currentRefinement = "",
  } = useSearchBox(props);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedHit, setSelectedHit] = useState("");
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setTimeout(() => setIsFocused(false), 200);
  };
  const handleHitClick = (hit) => {
    setSelectedHit(hit.vehicle_name);
    refine(hit.vehicle_name);
    props.onHitClick(hit);
  };

  const { items, results, banner, sendEvent } = useHits(props);

  useEffect(() => {
    //console.log(items,results);
    if (items.length === 1) {
      setSelectedHit(items[0].vehicle_name);
      //refine(hit.vehicle_name);
      props.onHitClick(items[0]);
    }
  }, [items,results]);


  return (
    <>
      <div className='form-group'>
        <div className="custom-searchbox position-relative">
          <input
            type='text' 
            value={selectedHit || currentRefinement || ''}
            onChange={event => {
              refine(event.currentTarget.value);
              setSelectedHit(event.currentTarget.value);
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name="vehicle"
            className="form-control"
            placeholder='Enter Year Make Model Trim'
          />
          {isFocused && (
            <Hits hitComponent={({ hit }) => (
              <div className="hit" onClick={() => handleHitClick(hit)}>
                  <p>{hit.vehicle_name}</p>
                </div>
            )} />
          )}
        </div>
      </div>
      <div className='form-group'>
        <button className='green-btn lg-btn w-100 text-uppercase' type='button' onClick={() => {props.onHitClick(items[0]);}}>Get offer</button>
      </div>
    </>
  );
}

const ExoticCarAppraisal = () => {
  const [trigger, setTrigger] = useState(false);
  const handleScrollEvent = async () => {
    const element = await document.getElementById("data");
    const elementPosition = await element.getBoundingClientRect().top;
    if (window.pageYOffset > elementPosition) {
      setTrigger(true);
    }
  };
  const increment = () => {
    console.log("Element is in view!");
  };
  const registerEvent = () => {
    document.addEventListener("scroll", handleScrollEvent);
  };
  const unRegisterEvent = () => {
    document.removeEventListener("scroll", handleScrollEvent);
  };
  useEffect(() => {
    if (trigger) {
      increment();
    }
    registerEvent();
    return () => {
      unRegisterEvent();
    };
  }, [trigger]);
  const [openAppraiseCar, setOpenAppraiseCar] = useState(false);
  const [inputValue, setInputValue] = useState({
    form1: '',
    form2: '',
    form3: '',
  });

  const getOfferModal = () => {
    setOpenAppraiseCar(true);
    document.body.classList.add('overflow-body');
  }
  const closeGetOfferModal = () => {
    setOpenAppraiseCar(false);
    document.body.classList.remove('overflow-body');
  }

  const formikRef = useRef(null);

  const handleSearchClick = (hit) => {
    formikRef.current.setFieldValue("vehicle",hit.vehicle_name);
    formikRef.current.setFieldValue("year",hit.year_is);
    formikRef.current.setFieldValue("make",hit.make_ss);
    formikRef.current.setFieldValue("model",hit.model_ss);
    formikRef.current.setFieldValue("trim",hit.trim_ss);
    console.log(hit,formikRef.current.values);
    setOpenAppraiseCar(true);
    document.body.classList.add('overflow-body');
  }


  return (
    <>
      <section className='eca-wrap position-relative h-675' style={{backgroundImage: `url(${getImages('eca-banner.webp')})`}}>
        {/* <img className='w-100' src={getImages('eca-banner.jpg')} alt='eca' /> */}
        <div className='eca-pos'>
          <div className='container'>
            <div className='d-flex align-items-center justify-content-between eca-pos-flex'>
              <div>
                <div className='eca-title1 wow fadeInLeft' data-wow-delay="0.3s">Sell Your Car</div><br />
                <div className='eca-title2 mt-3 wow fadeInLeft' data-wow-delay="0.6s">for Top Dollar</div>
              </div>
              <div className='eca-get-offer custom-form wow fadeInRight' data-wow-delay="0.6s">
                <div className='md-title text-center font-1-8em mb-4'>Get an offer for <br/>your car now!</div>
                <div className='eca-banner-form text-start'>
                  <InstantSearch indexName="taxonomy_vins" searchClient={searchClient}>
                    <CustomSearchBox onHitClick={handleSearchClick} />
                  </InstantSearch>
                </div>
                <div className='mt-5 text-center md-title'>
                  <img src={getImages('local-phone-material.svg')} /> &nbsp;&nbsp;<span className='text-green'>Call Us</span> &nbsp;&nbsp;800-123-4567
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='how-works-wrap py-70'>
        <div className='container'>
          <div className='md-title font-2-2em fw-900 text-uppercase mb-5 wow fadeInDown' data-wow-delay="0.2s">How it works?</div>
          <div className='row'>
            <div className='col-xl-6 col-lg-5 hw-left pe-5'>
              <div className='d-flex align-items-center wow fadeInLeft' data-wow-delay="0.4s">
                <div className='hw-circle font-1-8em fw-700 text-white'>1</div>
                <div className='ps-3'>
                  <div className='sm-title fw-700 text-uppercase font-1-2em mb-1'>Enter your vehicle Info</div>
                  <p className='text-lgray fw-500'>With just your VIN or year make model & trim we'll get the process started for you. We're here to make this simple.
                  </p>
                </div>
              </div>
              <div className='d-flex align-items-center wow fadeInLeft' data-wow-delay="0.6s">
                <div className='hw-circle font-1-8em fw-700 text-white'>2</div>
                <div className='ps-3'>
                  <div className='sm-title fw-700 text-uppercase font-1-2em mb-1'>Get your Offer</div>
                  <p className='text-lgray fw-500'>Our team will review your vehicle's demand in the current marketplace and give you the most competitive cash offer possible.</p>
                </div>
              </div>
              <div className='d-flex align-items-center wow fadeInLeft' data-wow-delay="0.8s">
                <div className='hw-circle font-1-8em fw-700 text-white'>3</div>
                <div className='ps-3'>
                  <div className='sm-title fw-700 text-uppercase font-1-2em mb-1'>Receive your cash</div>
                  <p className='text-lgray fw-500'>Just bring your vehicle by our showroom, or arrange for pickup and we'll cut you a check that same day. Easy & hassle free.</p>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-7 hw-right wow fadeInRight' data-wow-delay="1s">
              <div className='row g-5'>
                <div className='col-md-6'>
                  <div className='hwr-box d-flex align-items-center'>
                    <div className='hwr-icon'>
                      <img src={getImages('attach-money-material.svg')} />
                    </div>
                    <div className='hwr-title ps-4'>Get Top Dollar for your Vehicle</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='hwr-box d-flex align-items-center'>
                    <div className='hwr-icon'>
                      <img src={getImages('ion-android-checkmark-circle-ionicons.svg')} />
                    </div>
                    <div className='hwr-title ps-4'>Sell or Trade your Vehicle</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='hwr-box d-flex align-items-center'>
                    <div className='hwr-icon'>
                      <img src={getImages('icon-star.svg')} />
                    </div>
                    <div className='hwr-title ps-4'>Easy and Fast Process</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='hwr-box d-flex align-items-center'>
                    <div className='hwr-icon'>
                      <img src={getImages('icon-world-copy.svg')} />
                    </div>
                    <div className='hwr-title ps-4'>Satisfaction Guaranteed</div>
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mt-5 get-offer-block'>
                <div className='md-title text-uppercase fw-600'>GET AN OFFER <span className='text-green'>SAME DAY</span> <br />FOR YOUR CAR NOW!</div>
                <div className='w-50'>
                  <button type='button' className='green-btn lg-btn text-uppercase w-100' onClick={getOfferModal}>Get Offer</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='eca-count-wrap' id="data">
        <div className='container'>
          <div className='eca-count-box'>
            <div className='row reliable-block'>
              <div className='col-lg-3 col-md-6 py-1'>
                <div className='reliable-value mb-3'>
                  <CountUp start={0} end={30} duration={3}> +</CountUp>
                </div>
                <div className='reliable-label'>Years of Experience</div>
              </div>
              <div className='col-lg-3 col-md-6 py-1'>
                <div className='reliable-value mb-3'>
                  {trigger ? (
                    <>
                      <CountUp start={0} end={4200} duration={3}></CountUp>
                    </>
                  ) : (
                    <div>0</div>
                  )}
                </div>
                <div className='reliable-label'>Vehicles Bought</div>
              </div>
              <div className='col-lg-3 col-md-6 py-1'>
                <div className='reliable-value mb-3'>
                  {trigger ? (
                    <>
                      <CountUp start={0} end={3500} duration={3}></CountUp>
                    </>
                  ) : (
                    <div>0</div>
                  )}
                </div>
                <div className='reliable-label'>Satisfied Customers</div>
              </div>
              <div className='col-lg-3 col-md-6 py-1'>
                <div className='reliable-value mb-3'>
                  {trigger ? (
                    <>
                      <CountUp start={0} end={140} duration={3}></CountUp>
                    </>
                  ) : (
                    <div>0</div>
                  )}
                </div>
                <div className='reliable-label'>Cities Served</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='recently-vehicles-wrap'>
        <div className='container'>
          <div className='reliable-title text-white text-start text-uppercase mb-3'>Recently purchased vehicles</div>
          <div className='row wow fadeInUp' data-wow-delay="0.4s">
            <div className='col-lg-3 col-md-6 col-6 mb-3'>
              <div className='rpv-box'>
                <div className='rpv-img'>
                  <img src={getImages('rpv-img1.jpg')} />
                </div>
                <div className='rpv-content'>
                  <div className='d-flex align-items-center mb-3 justify-content-between'>
                    <div className='sm-title fw-900 font-1-2em'>Billy P</div>
                    <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                  </div>
                  <div className='rpv-address text-white'>New York, NY</div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-6 mb-3'>
              <div className='rpv-box'>
                <div className='rpv-img'>
                  <img src={getImages('rpv-img2.jpg')} />
                </div>
                <div className='rpv-content'>
                  <div className='d-flex align-items-center mb-3 justify-content-between'>
                    <div className='sm-title fw-900 font-1-2em'>Billy P</div>
                    <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                  </div>
                  <div className='rpv-address text-white'>New York, NY</div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-6 mb-3'>
              <div className='rpv-box'>
                <div className='rpv-img'>
                  <img src={getImages('rpv-img3.jpg')} />
                </div>
                <div className='rpv-content'>
                  <div className='d-flex align-items-center mb-3 justify-content-between'>
                    <div className='sm-title fw-900 font-1-2em'>Billy P</div>
                    <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                  </div>
                  <div className='rpv-address text-white'>New York, NY</div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-6 mb-3'>
              <div className='rpv-box'>
                <div className='rpv-img'>
                  <img src={getImages('rpv-img1.jpg')} />
                </div>
                <div className='rpv-content'>
                  <div className='d-flex align-items-center mb-3 justify-content-between'>
                    <div className='sm-title fw-900 font-1-2em'>Billy P</div>
                    <div className='sold-btn d-inline-block text-uppercase'>Sold</div>
                  </div>
                  <div className='rpv-address text-white'>New York, NY</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='profit-margins-wrap profile-logos-wrap eca-logos'>
        <div className='container mxw-1230'>
          <div className='d-flex align-items-center justify-content-between pm-logos'>
            <div className='wow fadeInUp' data-wow-delay="0.4s">
              <img src={getImages('logo1.svg')} />
            </div>
            <div className='wow fadeInUp' data-wow-delay="0.5s">
              <img src={getImages('logo2.webp')} />
            </div>
            <div className='wow fadeInUp' data-wow-delay="0.6s">
              <img src={getImages('logo3.webp')} />
            </div>
            <div className='wow fadeInUp' data-wow-delay="0.7s">
              <img src={getImages('logo4.webp')} />
            </div>
            <div className='wow fadeInUp' data-wow-delay="0.8s">
              <img src={getImages('logo5.webp')} />
            </div>
            <div className='wow fadeInUp' data-wow-delay="0.9s">
              <img src={getImages('logo6.webp')} />
            </div>
          </div>
        </div>
      </section>
      <section className='get-offer-wrap wow fadeInUp' data-wow-delay="0.4s">
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between pl-flex'>
            <div className='md-title font-1-8em'>Find out what your vehicle is worth?</div>
            <div className='d-flex get-offer-form w-50  text-start'>
              <InstantSearch indexName="taxonomy_vins" searchClient={searchClient} >
                <CustomSearchBox onHitClick={handleSearchClick}/>
              </InstantSearch>
            </div>
          </div>
        </div>
      </section>
      <section className='faqs-wrap wow fadeInUp' data-wow-delay="0.5s">
        <div className='container'>
          <div className='md-title font-2-2em fw-900 text-uppercase mb-4'>Frequently Asked Questions</div>
          <div className="accordion custom-accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                How do I sell my luxury or exotic car?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Simple. Send us your car's basic info. Our agents will analyze the market & appraise your car. Receive a cash offer same day. No hassle, no fuss.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                My exotic car is currently being leased is that okay?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Yes. Our offer will be valid regardless of your vehicles lease or finance terms. We can buyout your current contract from third parties no problem. It is helpful if you have a document showing your exotic vehicle's payoff amount.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How long does the process typically take?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                We've completed many purchases the same day, but we are happy to move at your convenience. Once we have the documents from you, we can typically process them the same day.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                What about unique exotic cars with aftermarket work?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Let us know what customizations you have! We'll price them competitively and have an extensive network make a connection with an interested buyer.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Still not convinced?</button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Give us a call! We'd be happy to chat through any questions you might have <a href="tel:8001234567" className='text-green'>800-123-4567</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='get-instant-wrap'>
        <div className='container'>
          <div className='get-instant-box wow fadeInUp' data-wow-delay="0.5s">
            <div className='md-title font-2-2em text-center text-uppercase fw-900'>GET AN OFFER <span className='text-green'>SAME DAY</span> FOR YOUR CAR NOW!
            </div>
            <div className='mt-4 text-center'>
              <div className='d-inline-flex align-items-center get-instent-form text-start'>
                  {/*<div className='me-md-3 me-0'>
                    <InstantSearch indexName="taxonomy_vins" searchClient={searchClient} >
                      <CustomSearchBox onHitClick={handleSearchClick}/>
                    </InstantSearch>
                  </div>
                  <div>
                    <button 
                      className='green-btn lg-btn w-100 text-uppercase'
                      onClick={handleSubmit}
                    >Get offer</button>
                  </div>*/}
                  <InstantSearch indexName="taxonomy_vins" searchClient={searchClient} >
                      <CustomSearchBox onHitClick={handleSearchClick}/>
                    </InstantSearch>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalLayout open={openAppraiseCar}  darkThemeCls='true' modalWidth={857}>
          <AppraiseCarModal 
            close={closeGetOfferModal} 
            formikRef={formikRef}
          />
      </ModalLayout>
    </>
  )
}

export default ExoticCarAppraisal