import {createContext, useEffect, useState} from 'react';
import axios from 'axios'

export const AuctionContext = createContext();

export const AuctionContextProvider = ({children}) => {
    const baseURL = window.location.host.includes('dev.kentmotorclub') ? "http://dev.kentmotorclub.com/" : "https://www.kentmotorclub.com/";

    const Axios = axios.create({ baseURL: baseURL });

    const [auctionData, setAuctionData] = useState(false);

    const [auctionDetailsByVIN,setAuctionDetailsByVIN] = useState([]);

    const getAuctionData = async () => {

        if(auctionData) return;
        
        //setOpenLoadingModal(true);
        try {
            //const loginToken = localStorage.getItem('loginToken');
            //Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;	

            const response = await Axios.get('bridge/auction/auctions.php',{
                retry: 0, retryDelay: 3000
            });

            console.log("all auction data",response.data);

            setAuctionData(response.data);

        } catch (error) {
            console.error('Error fetching auctions:', error);
            //setOpenLoadingModal(false);
            return {}; // Or handle error as needed
        }
    };

    const getAuctionDetails = async (vin) => {
        //setOpenLoadingModal(true);

        if(vin in auctionDetailsByVIN) {
            return auctionDetailsByVIN[vin]
        } else {
            try {
                //const loginToken = localStorage.getItem('loginToken');
                //Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

                var values = {
                    "vin" : vin
                };

                const response = await Axios.post('bridge/auction/auction_details.php',values);

                console.log("auction data",response.data);

                var currentDetails = auctionDetailsByVIN;

                currentDetails[vin] = response.data;

                setAuctionDetailsByVIN(currentDetails);

                return response.data;

            } catch (error) {
                console.error('Error fetching auctions:', error);
                //setOpenLoadingModal(false);
                return {}; // Or handle error as needed
            }
        }
    };

    const updateAuctionData = async (vin,updateData) => {
        try {
            //const loginToken = localStorage.getItem('loginToken');
            //Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            var values = {
                "vin" : vin,
                "updateData" : updateData
            };

            const response = await Axios.post('bridge/auction/update_auction.php',values);

            console.log(response.data);

            return response.data;

        } catch (error) {
            console.error('Error fetching auctions:', error);
            //setOpenLoadingModal(false);
            return {}; // Or handle error as needed
        }
    }

    const submitBid = async (vin,username,bid) => {
        try {
            //const loginToken = localStorage.getItem('loginToken');
            //Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            var values = {
                "vin" : vin,
                "username" : username,
                "bid" : bid
            };

            const response = await Axios.post('bridge/auction/submit_bid.php',values);

            console.log(response.data);

            return response.data;

        } catch (error) {
            console.error('Error fetching auctions:', error);
            //setOpenLoadingModal(false);
            return {}; // Or handle error as needed
        }
    }

    const buyItNow = async (vin,username,bid) => {
        try {
            //const loginToken = localStorage.getItem('loginToken');
            //Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            var values = {
                "vin" : vin,
                "username" : username,
                "bid" : bid
            };

            const response = await Axios.post('bridge/auction/buy_it_now.php',values);

            console.log(response.data);

            return response.data;

        } catch (error) {
            console.error('Error fetching auctions:', error);
            //setOpenLoadingModal(false);
            return {}; // Or handle error as needed
        }
    }

    useEffect(() => {

        console.log("auctioncontext useEffect");

    },[]);

    return (
        <>
            <AuctionContext.Provider value={{auctionData,getAuctionData,getAuctionDetails,updateAuctionData,submitBid,buyItNow}}>
                {children}
            </AuctionContext.Provider>
        </>
    );
}

export default AuctionContextProvider