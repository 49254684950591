import {createContext, useState, useEffect, useContext} from 'react'
import axios from 'axios'

export const UserContext = createContext();

export const UserContextProvider = ({children}) => {

    const baseURL = window.location.host.includes('dev.kentmotorclub') || window.location.host.includes('localhost') ? "http://dev.kentmotorclub.com/" : "https://www.kentmotorclub.com/";

    const Axios = axios.create({
        baseURL: baseURL + "bridge/user/"
    });

    const [userData, setUserData] = useState(null);
    const [wait, setWait] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    const [allUserData,setAllUserData] = useState([]);

    const registerUser = async ({name,signin_username,signin_password}) => {
        setWait(true);
        try{
            const {data} = await Axios.post('register.php',{
                name,
                signin_username,
                signin_password 
            });
            setWait(false);
            return data;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
    }

    const loginUser = async ({signin_username,signin_password}) => {
        console.log(signin_username);
        setWait(true);
        try{
            const {data} = await Axios.post('login.php',{
                signin_username,
                signin_password 
            });

            console.log(data);
            if(data.success && data.token){
                localStorage.setItem('loginToken', data.token);
                setWait(false);
                return {success:1};
            }
            setWait(false);
            return {success:0, message:data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }

    }

    const loggedInCheck = async () => {
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        console.log("loggedInCheck");
        if(loginToken){
            const {data} = await Axios.get('getUser.php');
            console.log(data);
            if(data.success && data.user){
                data.user.admin = data.user.admin == "1";
                setUserData(data.user);
                return;
            }
            setUserData(null);
        } 
    }

    const forgotPassword = async (email) => {

        console.log(email);

        const {data} = await Axios.post('forgotPassword.php',{
            email
        });

        console.log(data);

        return data;
    }

    const registrationForm = async (formData) => {

        console.log(formData);

        const {data} = await Axios.post('signUp.php',{
            ...formData
        });

        console.log(data);

        return data;
    }

    const addUser = async (values) => {

        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
    
        const {data} = await Axios.post('addUser.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            // email_axios_error(error_data);
        });
    
        console.log(data);
    
        return data;
    }

    const changeUserData = async (values) => {

        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
    
        const {data} = await Axios.post('changeUserData.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            // email_axios_error(error_data);
        });
    
        console.log(data);
    
        return data;
    }

    const deleteUser = async (values) => {

        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
    
        const {data} = await Axios.post('deleteUser.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            // email_axios_error(error_data);
        });
    
        console.log(data);
    
        return data;
    }

    const getUsers = async () => {

        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

        const {data} = await Axios.post('getUsers.php');

        console.log(data);

        setAllUserData(data.users ? data.users : {});
    }

    useEffect(() => {
        console.log("UserContext.js useEffect",userData);

        if(userData && userData.admin) getUsers();
    },[userData]);

    const logout = () => {
        localStorage.removeItem('loginToken');
        setUserData(null);
        window.location.reload(false);
    }

    return (
        <UserContext.Provider value={{registerUser,loginUser,wait, user:userData, dataLoading, setDataLoading, loggedInCheck,logout,forgotPassword,registrationForm,addUser, getUsers, changeUserData, deleteUser, allUserData}}>
            {children}
        </UserContext.Provider>
    );

}

export default UserContextProvider;